import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class TechnicalScreen extends Vue {
  @Prop({ type: Boolean, default: false }) private readonly loading!: boolean;
  @Prop({ type: String, default: "" }) private readonly title!: string;
  @Prop({ type: String, default: "default" }) private readonly skin!: string;
  @Prop({ type: String, default: "" }) private readonly subtitle!: string;
  @Prop({ type: String, default: "md" }) private readonly subtitleSize!: string;
  @Prop({ type: Array, default: () => [] }) private readonly text!: string[];

  private get minHeight() {
    return this.$vuetify.breakpoint.height;
  }

  private onClickButton() {
    this.$emit("click:button");
  }
}
