var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{class:[
    'technical-screen',
    `technical-screen_skin_${_vm.skin}`,
    {
      'flex-column-reverse': _vm.$vuetify.breakpoint.mdAndDown,
    },
  ],style:({
    height: _vm.$vuetify.breakpoint.mdAndDown ? undefined : `${_vm.minHeight}px`,
  }),attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex technical-screen__inner",attrs:{"cols":"12","lg":"6"}},[_c('div',{class:[
        'technical-screen__logo-wrap',
        'mx-auto',
        'd-flex',
        'align-center',
        {
          'justify-end': _vm.$vuetify.breakpoint.mdAndDown,
          'justify-space-between': !_vm.$vuetify.breakpoint.mdAndDown,
        },
      ]},[(!_vm.$vuetify.breakpoint.mdAndDown)?_c('div',{staticClass:"technical-screen__logo-link cursor-pointer d-block pa-0",attrs:{"text":""},on:{"click":_vm.onClickButton}},[_c('img',{staticClass:"technical-screen__logo d-block",attrs:{"src":require("@/assets/img/logo-gray.svg")}})]):_vm._e(),_c('div',[_c('LangSwitcher',{attrs:{"dense":""}})],1)]),_c('div',{class:[
        'ma-auto',
        'technical-screen__content',
        {
          'px-2': _vm.$vuetify.breakpoint.mdAndDown,
          'px-4': !_vm.$vuetify.breakpoint.mdAndDown,
          'pt-8': _vm.$vuetify.breakpoint.mdAndDown,
          'py-10': !_vm.$vuetify.breakpoint.mdAndDown,
        },
      ]},[_c('div',{staticClass:"py-10"},[(_vm.loading)?[_c('v-skeleton-loader',{attrs:{"type":"heading"}}),_c('v-skeleton-loader',{staticClass:"mt-4",attrs:{"type":"text"}}),_c('v-skeleton-loader',{staticClass:"mt-4",attrs:{"type":"text"}}),_c('v-skeleton-loader',{staticClass:"mt-8",attrs:{"type":"heading"}})]:[(_vm.title)?_c('h1',{staticClass:"mb-3 technical-screen__title font-weight-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('h2',{class:[
              'mb-3',
              'technical-screen__subtitle',
              'font-weight-bold',
              `technical-screen__subtitle_${_vm.subtitleSize}`,
            ]},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]),_vm._l((_vm.text),function(value,index){return _c('p',{key:index,staticClass:"technical-screen__text mb-3",domProps:{"innerHTML":_vm._s(value)}})}),_vm._t("append-text"),_c('v-btn',{staticClass:"mt-7",attrs:{"min-width":_vm.$vuetify.breakpoint.mdAndUp ? 250 : undefined,"min-height":"40","color":"primary"},on:{"click":_vm.onClickButton}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.error_page.back_to_home"))+" ")])]],2)])]),_c('v-col',{staticClass:"technical-screen__bg d-lg-flex align-lg-center justify-lg-center",attrs:{"cols":"12","lg":"6"}},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('router-link',{staticClass:"technical-screen__logo-link d-block",attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"technical-screen__logo d-block",attrs:{"src":require("@/assets/img/logo-blue.svg")}})]):_vm._e(),_c('div',{staticClass:"flex-shrink-1 flex-grow-1 mt-13 mt-lg-0",style:({
        width: _vm.$vuetify.breakpoint.lgAndUp ? 0 : undefined,
        padding: _vm.$vuetify.breakpoint.mobile ? '40px' : '80px',
      })},[_vm._t("icon")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }